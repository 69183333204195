.lgRoot {
    display: flex;
    justify-content:space-around;
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    height: '90vh'!important;
    padding: 50px, 0px!important;
}
.lgImage {
    background-image: url("../../MR.png");
    background-repeat: 'no-repeat';
    background-position: 'center';
    background-size: 100% 100%;
    width: 45%;
    height: 600px;
}
.lgPaper {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    width: 45%;
    padding-left: 3%;
    padding-right: 3%;
}
.lgTitulo, .lgTitulo h3 {
    margin-top: 15%;
    text-align: center;
}
.lgTitulo h3 {
    font-weight: 700;
}
.lgAvatar {
    text-align: center;
    padding: 7%;
}
.lgForm {
    width: '100%';
    margin-top: 10px;
}
.lgSubmit {
    margin: 10px, 0px;
    padding: 20px, 0px;
}

@media screen and (min-width: 0px) and (max-width: 720px) {
    .lgImage{ display: none; }
    .lgPaper{ width: 80%; 
            margin-top: 20%; 
            height: 70%;}
    .lgTitulo{ font-size: x-large;
             margin-top: 40px;}
}
